import React, { useState, useCallback, useEffect } from "react";
import PhotoAlbum from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ClipLoader from "react-spinners/ClipLoader";


const photos = [
  {
    src: 'https://lharquitetura.com/images/portfolio/01.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/02.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/03.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/04.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/05.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/06.png',
    width: 1,
    height: 1
  },
  {
    src: 'https://lharquitetura.com/images/portfolio/07.png',
    width: 1,
    height: 1
  },
];

function Gallery(){
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
    useEffect(() => {
      let loadedImages = 0;
      let minimumLoadingTimeElapsed = false;

      const timer = setTimeout(() => {
        minimumLoadingTimeElapsed = true;
        if (loadedImages === photos.length) {
          setLoading(false);
        }
      }, 1500);
      photos.forEach((photo) => {
        const img = new Image();
        img.src = photo.src;
        img.onload = () => {
          loadedImages += 1;
          if (loadedImages === photos.length && minimumLoadingTimeElapsed) {
            setLoading(false); // Todas as imagens foram carregadas
          }
        };
        img.onerror = () => {
          loadedImages += 1;
          if (loadedImages === photos.length) {
            setLoading(false); // Mesmo erro conta como carregado
          }
        };
      });
      return () => clearTimeout(timer);
    }, [photos]
    );
    
  return (
    <div>
    {loading ? (
      <div className="loading-container">
        <ClipLoader color="#FFC0CB" size={60} />
      </div>
    ) : (
      <div>
      <PhotoAlbum photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
    )}
  </div>
  );
}

export default Gallery
